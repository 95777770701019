/* General Reset and Styling */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ab9ff2, #9945fd);
}

/* Main Container Styling */
.app-container {
    background: #222222;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 325px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 5px;
}

/* Title */
h1 {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 20px;
}

/* Wallet Connect Button */
#connect-wallet {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    background-color: #3c315b;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}
#connect-wallet:hover {
    background-color: #50597b;
}

/* Cryptocurrency Selection */
.radio-dropdown {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.radio-dropdown input[type="radio"] {
    display: none;
}

.radio-dropdown label {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #433c4f;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    border: 1px solid #ab9ff2;
    color: white;
}

.radio-dropdown label:hover {
    background-color: #444d50;
}

.radio-dropdown input[type="radio"]:checked + label {
    background-color: #ab9ff2;
    border-color: #2ec08b;
    color: white;
}

/* Input Fields */
input[type="number"], input[type="text"] {
    width: 100%;
    padding: 5px;
    margin-top: 0px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 15px;
    text-align: center;
}

/* Keypad Styling */
.keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    margin-top: 10px;
}

.keypad button {
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    background-color: #000000;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.keypad button:hover {
    background-color: #ab9ff2;
}

.keypad button:active {
    background-color: #3c315b;
}

.keypad .clear {
    background-color: #ff7243;
    color: white;
}

/* Generate QR Code Button */
#generate-qr {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    background-color: #2ec08b;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    margin-bottom: 10px;
}
#generate-qr:hover {
    background-color: #25a671;
}

/* Modal styling */
.modal {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #2ec08b;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: grid;
    justify-content: center;
}

.modal-content h3 {
    margin-bottom: 15px;
    color: white;
}

.close-button {
    background-color: #ff7243;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 16px;
}

#qr-canvas {
    margin-top: 10px;
    width: 100%;
    height: auto;
}

/* Footer Links */
.links {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.links img {
    width: 30px;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s;
}

.links img:hover {
    transform: scale(1.1);
}
.icon {
    width: 25px;
    height: auto; /* This maintains the aspect ratio */
    margin-right: 5px; /* Optional: Adds some spacing between the image and the label text */
    vertical-align: middle; /* Aligns the image with the text */
}
.logo {
width: 180px;
}
.footer-icon {
    width: 25px;
    margin: 0px 18px 0px 18px;
}
